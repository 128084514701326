<template>
  <v-card min-width="270" class="border" rounded="lg">
    <v-list class="pa-2">
      <v-list-item
        :href="links.brochure"
        target="_blank"
        class="px-2"
        rounded="lg"
      >
        <template #title>
          <span class="text-3.5">Help Center</span>
        </template>
      </v-list-item>
      <v-list-item @click="help" class="px-2" rounded="lg">
        <template #title>
          <span class="text-3.5">Keyboard shortcuts</span>
        </template>
        <template #append>
          <div
            class="text-3 bg-gray/20 rounded-md w-20px h-20px flex-align justify-center"
          >
            <span>?</span>
          </div>
        </template>
      </v-list-item>
    </v-list>
    <!-- <v-divider />
    <v-list class="pa-2">
      <v-list-item class="px-2" rounded="lg" link>
        <template #title>
          <span class="text-3.5">Upgrade plan</span>
        </template>
      </v-list-item>
      <v-list-item class="px-2" rounded="lg" link>
        <template #title>
          <span class="text-3.5">Get the app</span>
        </template>
      </v-list-item>
    </v-list> -->
    <v-divider />
    <v-list class="pa-2">
      <v-list-item v-if="user" class="px-2" :subtitle="user.email" lines="two">
        <template #title>
          <span class="text-3.5 font-bold">{{ user.name }}</span>
        </template>
      </v-list-item>
      <v-list-item
        :to="{ name: 'settings-profile' }"
        class="px-2"
        rounded="lg"
        link
      >
        <template #title>
          <span class="text-3.5">Manage account</span>
        </template>
      </v-list-item>
      <v-list-item @click="logout" class="px-2" rounded="lg">
        <template #title>
          <span class="text-3.5">Log out</span>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const auth = useAuth();
    const { links } = useRuntimeConfig().public;

    return {
      user: auth.user,
      links,
    };
  },
  methods: {
    help() {
      this.$broadcast("app:help");
    },
    logout() {
      this.$broadcast("app:logout");
    },
  },
});
</script>
